// import livingList from "./living";

//1.元数据
export const liveList = [
  // ...livingList,
  {
    imgSrc: require("../assets/images/home_jpkc_img1.png"),
    avatar: require("../assets/images/msym_head12.png"),
    lecturerName: "黄教云",
    title: "初二历史全年课程 同步拓展 中考冲刺",
    numberOfWatch: "1876",
    id: "21040910464505898200355092498001",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img2.png"),
    avatar: require("../assets/images/msym_head11.png"),
    lecturerName: "李晓林",
    title: "初中英语语法精华完整版",
    numberOfWatch: "1226",
    id: "21040910464505898200355092498002",
    subject: "english",
    subjectName: "英语",
    videoType: "grammar",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/interact_1.jpg"),
    avatar: require("../assets/images/msym_head10.png"),
    lecturerName: "肖敏芳",
    title: "光宝中学专递课堂宋代经济的发展 ",
    numberOfWatch: "1116",
    isInteract: true,
    id: "21040910464505898200355092498003",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img3.png"),
    avatar: require("../assets/images/msym_head9.png"),
    lecturerName: "张彻名",
    title: "初二物理全年课程 同步拓展 中考冲刺",
    numberOfWatch: "1163",
    id: "21040910464505898200355092498004",
    subject: "physics",
    subjectName: "物理",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img3.png"),
    avatar: require("../assets/images/msym_head8.png"),
    lecturerName: "余琳琳",
    title: "初中数学总复习 初一 初二 初三 知识点+例题",
    numberOfWatch: "1163",
    id: "21040910464505898200355092498005",
    subject: "math",
    subjectName: "数学",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_imh6.png"),
    avatar: require("../assets/images/msym_head6.png"),
    lecturerName: "林振坤",
    title: "中考初中物理从入门到学霸的方法",
    numberOfWatch: "1163",
    id: "21040910464505898200355092498006",
    subject: "physics",
    subjectName: "物理",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/interact_2.jpg"),
    avatar: require("../assets/images/msym_head10.png"),
    lecturerName: "肖敏芳",
    title: "光宝中学专递课堂走进汉武帝",
    numberOfWatch: "1752",
    isInteract: true,
    id: "21040910464505898200355092498007",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img7.png"),
    avatar: require("../assets/images/msym_head1.png"),
    lecturerName: "周志明",
    title: "初二历史全年课程 同步拓展 中考冲刺",
    numberOfWatch: "1163",
    id: "21040910464505898200355092498008",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img1.png"),
    avatar: require("../assets/images/msym_head6.png"),
    lecturerName: "林振坤",
    title: "浮力压强简单机械专题拔高",
    numberOfWatch: "1784",
    id: "21040910464505898200355092498009",
    subject: "physics",
    subjectName: "物理",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img2.png"),
    avatar: require("../assets/images/msym_head12.png"),
    lecturerName: "黄教云",
    title: "初中英语语法知识学习-跟海斌老师学语法 ",
    numberOfWatch: "1263",
    id: "21040910464505898200355092498010",
    subject: "english",
    subjectName: "英语",
    videoType: "grammar",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img3.png"),
    avatar: require("../assets/images/msym_head10.png"),
    lecturerName: "肖敏芳",
    title: "外研版初中英语七年级下册 同步教材精讲精练",
    numberOfWatch: "1258",
    id: "21040910464505898200355092498011",
    subject: "english",
    subjectName: "英语",
    videoType: "grammar",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img5.png"),
    avatar: require("../assets/images/msym_head6.png"),
    lecturerName: "林振坤",
    title: "浮力的四种算法",
    numberOfWatch: "1089",
    id: "21040910464505898200355092498012",
    subject: "physics",
    subjectName: "物理",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img6.png"),
    avatar: require("../assets/images/msym_head10.png"),
    lecturerName: "肖敏芳",
    title: "初二历史全年课程 同步拓展 中考冲刺",
    numberOfWatch: "1325",
    id: "21040910464505898200355092498013",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img7.png"),
    avatar: require("../assets/images/msym_head2.png"),
    lecturerName: "丁子江",
    title: "初三政治全年课程 同步拓展考前冲刺",
    numberOfWatch: "1424",
    id: "21040910464505898200355092498014",
    subject: "politics",
    subjectName: "政治",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img4.png"),
    avatar: require("../assets/images/msym_head4.png"),
    lecturerName: "方志德",
    title: "初三物理上册知识精讲",
    numberOfWatch: "1412",
    id: "21040910464505898200355092498015",
    subject: "physics",
    subjectName: "物理",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_wk_img4.png"),
    avatar: require("../assets/images/msym_head12.png"),
    lecturerName: "黄教云",
    title: "初中语文古诗词详解【部编版】全面掌握诗...",
    numberOfWatch: "1635",
    id: "21040910464505898200355092498016",
    subject: "lang",
    subjectName: "语文",
    schoolName: "福建师范",
  },
  {
    imgSrc: require("../assets/images/home_jpkc_img8.png"),
    avatar: require("../assets/images/msym_head10.png"),
    lecturerName: "肖敏芳",
    title: "2021中考历史总复习精讲系列",
    numberOfWatch: "1324",
    id: "21040910464505898200355092498017",
    subject: "history",
    subjectName: "历史",
    schoolName: "福建师范",
  },
];
export default liveList;

//2.数据操作
function filterType(list, type) {
  if (type === "interact") {
    return list.filter((item) => item.isInteract);
  } else {
    return list;
  }
}
function filterState(list, state) {
  if (state === "living") {
    return list.filter((item) => item.isLive);
  } else if (state === "lived") {
    return list.filter((item) => !item.isLive);
  } else {
    return list;
  }
}
function filterPage(list, page) {
  if (page === 1) {
    return list.slice(0, 15);
  } else if (page === 2) {
    return list.slice(15);
  } else {
    return list;
  }
}
export const searchList = [
  // ...livingList,
  {
    imgSrc: require("../assets/images/interact_1.jpg"),
    avatar: require("../assets/images/home_jpkc_head8.png"),
    lecturerName: "肖敏芳",
    title: "光宝中学专递课堂宋代经济的发展 ",
    numberOfWatch: "1116",
    isInteract: true,
  },
  {
    imgSrc: require("../assets/images/interact_2.jpg"),
    avatar: require("../assets/images/home_jpkc_head8.png"),
    lecturerName: "肖敏芳",
    title: "光宝中学专递课堂走进汉武帝",
    numberOfWatch: "1752",
    isInteract: true,
  },
];
export function loadData(type, state, page = 1) {
  let res = {};
  const list1 = filterType(liveList, type);
  const list2 = filterState(list1, state);
  const list3 = filterPage(list2, page);
  res.total = list2.length;
  res.data = list3;

  return res;
}
