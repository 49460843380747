<template>
  <div class="container main-inner">
    <div class="bread">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item class="highlight">直播</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-divider></el-divider>
    <el-container>
      <el-container class="main_content">
        <Select @getData="getData"></Select>
        <el-main>
          <div class="statistics">
            <span class="filtrate">筛选结果</span>
            <span class="num" v-text="total"></span>
            <span class="filtrate">条</span>
          </div>
          <div class="Grid">
            <live-card
              v-for="(item, index) in liveList"
              :key="index"
              @skipTo="skipTo"
              :item="item"
            ></live-card>
          </div>
          <!-- 分页 -->
          <ListPage
            v-if="total"
            v-model:curPage="queryForm.pageCurrent"
            @pageChanged="getData"
            :total="total"
            :pSize="queryForm.pageSize"
          ></ListPage>
        </el-main>
      </el-container>
      <el-aside width="285px">
        <div class="title">
          <span class="title_live">直播推荐</span>
          <span class="flex cursor" @click="$router.push('/appointment')">
            <span class="title_more">更多</span>
            <i class="iconfont icon-more2"></i>
          </span>
        </div>
        <el-divider></el-divider>
        <div class="layout">
          <RecCard @getData="getData" :teachers="arr"></RecCard>
          <div
            v-show="arr.length === 0"
            style="text-align: center; color: #999999; margin-bottom: 20px"
          >
            暂无数据
          </div>
        </div>
      </el-aside>
    </el-container>
    <Login ref="Login" />
  </div>
</template>

<script>
import RecCard from "../../../components/RecCard/index";
import LiveCard from "../../../components/LiveCard/index";
import Select from "../../../components/Select/index";
import ListPage from "../../../components/ListPage/index";
import fakedata from "../../../models/live";
import apiLive from "@/api/live";
import { defineComponent } from "vue";
import publicfun from "@/scripts/publicfun";
import Login from "@/components/TopBar/components/LoginDialog.vue";
import { getAssetsFile } from "@/scripts/utils";
export default defineComponent({
  components: {
    RecCard,
    LiveCard,
    Select,
    ListPage,
    Login,
  },
  data() {
    return {
      liveList: [],
      arr: [],
      queryForm: {
        pageCurrent: 1,
        pageSize: 20,
      },
      pageCurrent: 1,
      pageSize: 20,
      total: 0,
    };
  },
  created() {
    this.getData({
      state: 3,
    });
    this.getData({
      state: 0,
      startMin: String(Date.parse(new Date())),
    });
  },
  methods: {
    async getData(obj) {
      obj.pageCurrent = this.queryForm.pageCurrent;
      obj.pageSize = this.queryForm.pageSize;
      if (obj.state === 0) {
        obj.pageSize = 8;
      }
      const res = await apiLive.list(obj);
      if (res.data && obj.state !== 0) {
        this.liveList = [];
        if (
          res.data.list &&
          Array.isArray(res.data.list) &&
          res.data.list.length > 0
        ) {
          this.liveList = res.data.list.map((item) => {
            let ava = "";
            const random = Math.floor(Math.random() * 10) + 1;
            if (window.Config.isDemostrate) {
              ava = getAssetsFile(`teacher${random}.png`, "png");
            } else {
              ava = item.avatar;
            }
            return {
              ...item,
              avatar: ava,
            };
          });
        }
        this.AddFake();
        this.total = res.data.totalCount;
      }
      if (res.data && obj.state === 0) {
        if (
          res.data.list &&
          Array.isArray(res.data.list) &&
          res.data.list.length > 0
        ) {
          this.arr = res.data.list.map((element) => {
            return {
              ...element,
              time: publicfun.timejudge(element.gmtStart),
            };
          });
        }
      }
    },
    // 添加假数据
    AddFake() {
      const num = 15 - this.liveList.length;
      for (let i = 0; i < num; i++) {
        this.liveList.push(fakedata[i]);
      }
    },
    // 跳转详情界面
    skipTo(item) {
      if (localStorage.getItem("token")) {
        window.open(`/live/detail/${item.liveId}`, "_blank");
      } else {
        this.showLogin = true;
        this.$refs.Login.digShow();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.main_content {
  flex-direction: column;
}
.el-main {
  padding: 20px 20px 0 0;
}

//栅格
.Grid {
  display: grid;
  grid-template-columns: 285px 285px 285px;
  grid-gap: 0px 20px;
  margin-bottom: 40px;
}
.cursor {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_live {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 60px;
  }
  .title_more {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    margin-right: 7px;
  }
  .icon-more2 {
    color: #bbbbbb;
  }
}
.layout {
  padding-top: 20px;
}
</style>
