// 公共函数
const publicfun = {
  timejudge(str:number) {
    const date = new Date(str)
    const hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const mm =
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const MM =
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    const DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    if (new Date(str).toDateString() === new Date().toDateString()) {
      return '今天' + hh + ':' + mm
    } else {
      return MM + '月' + DD + '日 ' + hh + ':' + mm
    }
  },
}
export default publicfun
