<template>
  <div
    @click="skipTo(item)"
    :style="{ 'margin-bottom': item.keyword ? '40px' : '20px' }"
    class="live-card"
  >
    <d-cover :src="item.cover" :height="160"></d-cover>
    <div class="title-line flex">
      <span
        :style="
          item.subjectName === '历史'
            ? 'background:#b655c2;'
            : item.subjectName === '英语'
            ? 'background: #3399F0;'
            : 'background: #F09933;'
        "
        class="tag"
      >
        {{ item.subjectName }}
      </span>
      <div class="title">{{ item.title }}</div>
    </div>
    <div class="desc flex-between">
      <div class="teacher flex">
        <d-avatar :src="item.avatar"></d-avatar>
        <div class="info">
          <div class="name">{{ item.lecturerName }}</div>
          <span class="school">{{ item.schoolName }}</span>
        </div>
      </div>
      <div class="live-view">
        <span v-if="item.state === 1" class="live-gif"></span>
        <!-- views 少字段 -->
        <span class="view">{{ item.numberOfWatch }}人观看</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    item: {
      type: Object,
    },
    teacherShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLogin: false,
    };
  },
  methods: {
    async skipTo(item) {
      this.$emit("skipTo", item);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/base.scss";
.live-card {
  width: 285px;
  height: 228px;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 20px;
  cursor: pointer;
  .img {
    width: 100%;
    border-radius: 4px;
  }
  .title-line {
    margin-top: 5px;
    margin-bottom: 10px;
    .tag {
      white-space: nowrap;
      padding: 4px 5px;
      font-size: 12px;
      margin-right: 9px;
      color: #ffffff;
      border-radius: 2px;
    }
    .title {
      width: 220px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .desc {
    .teacher {
      .info {
        margin-left: 10px;
      }
    }
    .name {
      margin-bottom: 3px;
    }
    .school,
    .live-gif,
    .school,
    .view {
      font-size: 12px;
      color: #666666;
    }
  }
}
</style>
